<template>
  <v-toolbar class="mx-auto" dark :color="color" rounded>
    <slot  class="mx-auto" name="accion_retroceder"></slot>
    <v-toolbar-title>
      {{ titulo }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <slot name="custom_action"></slot>
    <v-tooltip bottom v-for="(action,index) in actions" :key="index">
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="$emit(action.value)" bottom dark v-bind="attrs" v-on="on" icon >
          <v-icon> {{ action.icon }} </v-icon>
        </v-btn>
      </template>
      <span> {{ action.name }} </span>
    </v-tooltip>
  </v-toolbar>
</template>
<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true,
    },
    activar_tool: {
      type: Boolean,
      default: true,
    },
    actions: {
      type: Array,
      default:  ()=> [],
    },
    color: {
      type: String,
      default: "indigo darken-4",
    },
    
  },
};
</script>
