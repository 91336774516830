<template>
    <v-app>
        <v-main>
            <v-container>
              
              <v-row>
                <v-col cols="8" class="mx-auto">
                   <v-select label="Seleciona el modo de subir archivos" filled rounded dense v-model="tipo_upload_archivos" :items="['Scanner','Seleccionar Archivo']">

                  </v-select>
                </v-col>
              </v-row>
              

              <v-window v-model="step">
                  <v-row>
                    <v-col>
                      <ToolbarMultiIcons 
                          :titulo="'Tramites de la placa: '+ $store.state.SIETT.form.placa+ ' '+ tramites[step].status " 
                          :activar_tool="true"
                          
                          :actions="[{icon: 'mdi-arrow-left', name: 'Anterior tramite', value:'next'},
                                      {icon: 'mdi-arrow-right', name: 'Siguiente tramite', value:'prev'},
                                      {icon: 'mdi-plus', name: 'Agregar tramite', value: 'add'},
                                      {icon: 'mdi-qrcode-scan', name:'Generar sticker QR', value: 'generar_qr'}
                                      
                                      
                                      
                                      ]"
                          @prev=" step < tramites.length-1 ? step++ : step = 0"
                          @next="step > 0 ? step-- : step = 0"
                          @add="add_tramite()"
                          @generar_qr="generar_qr()"
                         

                      >
                        <template v-slot:custom_action>
                         {{tramites.length==0 ?  '0/0' :`${step+1}/${tramites.length}`}}
                          
                        </template>
                      </ToolbarMultiIcons>
                    </v-col>
                  </v-row>
                  <v-row class="ma-5">
                    <v-col>
                      <v-sheet elevation="4">
                        <v-window-item v-for="(data,index_tramite) in tramites" :key="index_tramite">
                          <v-row>
                              <v-col>
                                <v-form @submit.prevent="" ref="form" v-model="valid" lazy-validation>
                                  <v-row>
                                    <v-col>
                                      
                                          <v-row>
                                              <v-col>
                                                
                                                  <v-autocomplete
                                                      :items="$store.state.SIETT.tramites"
                                                      v-model="data.Tipo_de_tramite"
                                                      item-text="name"
                                                      multiple
                                                      return-object
                                                      required
                                                      label="Tipo de tramite"
                                                      filled
                                                      rounded 
                                                      dense
                                                      
                                                  >

                                                  </v-autocomplete>
                                              </v-col>
                                          </v-row>
                                          <v-row>
                                              <v-col>
                                                  <v-text-field filled rounded dense label="Fecha" v-model="data.Fecha">

                                                  </v-text-field>
                                              </v-col>
                                              <v-col>
                                                  <v-text-field filled rounded dense label="Documento del propietario" v-model="data.Documento_del_propietario">

                                                  </v-text-field>
                                              </v-col>
                                          </v-row>
                                          <v-row>
                                              <v-col>
                                                  <v-text-field filled rounded dense label="Observaciones" v-model="data.Observaciones">

                                                  </v-text-field>
                                              </v-col>
                                          </v-row>
                                          <v-row>
                                              <v-col>
                                                  <v-text-field filled rounded dense label="Parque automotor" v-model="data.Parque_automotor">

                                                  </v-text-field>
                                              </v-col>
                                              <v-col>
                                                  <v-autocomplete :items="$store.state.SIETT.tipos_de_vehiculos" filled rounded dense label="Tipos de vehiculos" v-model="tipo_de_vehiculo">

                                                  </v-autocomplete>
                                              </v-col>
                                          </v-row>
                                      
                                    </v-col>
                                    </v-row>
                                      
                                      <v-row v-if="tipo_upload_archivos == 'Seleccionar Archivo'">
                                        <v-col>
                                          <Drop
                                            :permitir="'nop'"
                                            
                                            v-on:info="pruebaDrop"
                                          />
                                        </v-col>
                                      </v-row>
                                      
                                      <v-row v-else-if="tipo_upload_archivos == 'Scanner'" >
                                        <v-col cols="12">
                                          <Carousel 
                                              @move_image="set_index_file_to_move($event,index_tramite)" 
                                              @eliminar="eliminar_archivo($event,index_tramite)" 
                                              :imagenes.sync="data.imagenes"
                                              
                                              @save_image_edited="save_image_edited($event,index_tramite)" 
                                              @upload_images="upload_images($event,index_tramite)"
                                                      
                                          />
                                        </v-col>
                                        <v-col class="mx-5">
                                          <v-row>
                                              <v-col>
                                                  <v-btn
                                                  color="blue darken-3"
                                                  large
                                                  dark
                                                  rounded
                                                  elevation="5"
                                                  class="mt-4"
                                                  block
                                                  @click="getImages"
                                                
                                                  >
                                                  SCANNEAR
                                                  </v-btn>
                                              </v-col>
                                              <v-col  v-if="data.imagenes.length > 0">
                                                  <v-btn
                                                  color="blue darken-3"
                                                  large
                                                  dark
                                                  rounded
                                                  elevation="5"
                                                  class="mt-4"
                                                  block
                                                  @click="terminarProcesoArchivador(index_tramite)"
                                                  
                                                  >
                                                  
                                                  Finalizar
                                                  <v-icon>mdi-upload</v-icon>
                                                  </v-btn>
                                              </v-col>
                                        </v-row>
                                        </v-col>
                                          
                                      </v-row>
                                      
                                </v-form>
                              </v-col>
                        <v-divider vertical></v-divider>
                        
                          </v-row>
                        </v-window-item>
                        <v-row v-if="tramites.length==0">
                          <v-col>
                            <v-sheet>
                              <NotFound></NotFound>
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                
              </v-window>
             
                <!-- Imagen de "Rellenar Campos" -->
               
            </v-container>
           
             
            <v-dialog v-model="dialog_move_file" max-width="400">
                <v-card>
                    <v-card-title> Moveras la imagen en la posición: {{index_to_move.old+1}} </v-card-title>
                    <v-card-title> A la posición: {{index_to_move.new}} </v-card-title>
                    <v-card-text>
                    <v-text-field label="Indica la posición del traslado" v-model="index_to_move.new"> </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                    <v-btn dark @click="move_file(step)" block color="indigo darken-4"> Mover imagen </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>
<script>
//import FormScan from '../components/FormScan'
//import InputsTipoDocumental from "../formularios/InputsTipoDocumental";
// import Load from "./Load";
import Drop from "../Drop";
import Carousel from '../celulas/Carousel.vue'
import { mapState } from "vuex";
import NotFound from "../atomos/NotFound.vue";
import ToolbarMultiIcons from '../atomos/ToolbarMultiIcons.vue'
import  jsPDF  from "jspdf";
const axios = require("axios").default;
export default {
    props: {
        tramites: {
            type: Array,
            required: true
        }
    },
    components: {
    Drop,
    //InputsTipoDocumental,
    Carousel,
    NotFound,
    ToolbarMultiIcons
},
    data: () => ({
    vSelect: "",
    
    ciclo_fijo: false,
    //---------------------------------------------------------------------------------
    //Variables agregadas por fry que contienen los tipos documentales de la plataforma
    tipos_documentales: [],
    tipos_documentales_completo: [],
    nombre_endpoint_almacenamiento: "",
    datos_archivos: [],

    urlQr: "",
    dialogo_ciclo: false,

    //Variables que usa el recepcionista agregadas por fry
    correo_de_contacto: "",
    workflows: [],
    
    workflow_seleccionado: "",
    mostrar_workflow: false,
    nodes_workflow: [],
    //------------------------------------

    contadorImagensSubidas: 0,
    subiendoImagenes: false,

    nombreDocumento: "",
    load: false,

    now: null,
    nowMenu: false,

    urlscan: require("../../assets/img.png"),
    imagenes: [],
    scan: null,
    
    img: "",
    menu: false,

    scannerInicio: false,
    valid: true,

    select: [],

    items: [],
    itemsCompleto: [],
  

    selectDocumento: "",

    result: [],
    propiedades: null,
    carpeta: [],
    files: [],

    ws: null,
    indice: 1,

    index_to_move: {
      old: 0,
      new: 0,
    },
    dialog_move_file: false,

    position_to_insert: -1,

    tipo_upload_archivos: 'Seleccionar Archivo',
    tipo_de_vehiculo: "CARRO",
    step: 0,
    //files_format_blob: [],
  }),
  watch: {
      form: {
        handler: function (val) {

            let rango = {
                rango: val.placa.slice(0,3),
                numero: val.placa.slice(3)
            };

            let tipo_vehiculo = 'CARRO' 

            const validate_tipo_vehiculo =  new RegExp("(^\\d{2}[a-zA-Z]{1})$|^[a-zA-Z]{2}$|^\\d{2}$", 'g');

            let ward_tipo_vehiculo =  rango.numero.match(validate_tipo_vehiculo);

            if(ward_tipo_vehiculo!=null){
                if(ward_tipo_vehiculo.length>0){
                    tipo_vehiculo = 'MOTOCICLETA';
                }
            }

            const validate_tipo_vehiculo_2 = new RegExp("(MC\\d{6}|R\\d{6}|S\\d{6})",'g');

            let ward_tipo_vehiculo_2 = val.placa.match(validate_tipo_vehiculo_2);

            if(ward_tipo_vehiculo_2!=null){
                if(ward_tipo_vehiculo_2.length>0){
                    tipo_vehiculo = 'MAQUINARIA';
                }
            }

            console.log(tipo_vehiculo);

            this.tipo_de_vehiculo = tipo_vehiculo;
              
        },
        deep: true
    }
  },
  async created() {
    let _this = this;
    const that = this;
    console.log('Holsi')
    window.URL = window.URL || window.webkitURL;
    var wsImpl = window.WebSocket || window.MozWebSocket;

    this.ws = new wsImpl("ws://localhost:8181/");

    this.ws.onmessage = function (e) {
      console.log(e);
      try {
        if (e.data instanceof Blob) {
          //-----Estas son las funciones que debe ejecutar tu input----//
          that.seleccionarFoto(e.data,that.step);
          

          that.addFiles_2(e.data, that.$store.state.token, that.indice, "blob",that.step);
          //---------------------------------------------------------//
        }
      } catch (error) {
        console.log(error);
      }
    };

    this.ws.onopen = function () {
      console.log("Open ");
      console.log(that.indice);
      //btn_scan.removeAttribute('disabled');
      //download_app.style.display = 'none';
    };
    this.ws.onerror = function (e) {
      console.log(e);
      console.log("Error");
      _this.$store.commit(
        "error",
        "El programa SCAN APP no ha sido activado. Por favor inicielo en el escritorio e ingrese nuevamente a esta vista si desea ESCANEAR archivos, si ese no es el caso ignore este mensaje"
      );
      //btn_scan.setAttribute('disabled', '');
      //download_app.style.display = '';
    };

    this.$store.commit("crearToken", 25);
    this.$store.commit("auth", "parse");

    
    this.consultar_tipos_documentales(); //Funcion agregada por fry 13/02/2021
    this.consultar_tipos_de_vehiculos();
  },
  methods: {
    add_tramite(){
      try {
        this.tramites.push({
          Placa: "",
          Tipo_de_tramite: "",
          id_Tipo_de_tramite: "",
          Fecha: "",
          Documento_del_propietario: "",
          fecha_documento_int: 0,
          fecha_creacion_int: Date.parse(new Date()),
          order: Date.parse(new Date()),
          estatus: "HABILITADA",
          extended_properties: "{}",
          imagenes: [],
          datos_archivos: [],
          files: [],
          status: "",
          Observaciones: "",
          autor: this.$store.state.dataUser.dataUser.nomre,
          Parque_automotor: "PARTICULAR",
          Tipo_de_vehiculo: "",
          Rango_placa: "",
          Numero_rango_placa: "",



        });
        this.step = this.tramites.length-1;
      } catch (error) {
        console.log(error);
      }
    },
   
    eliminar_archivo(indice,index_tramite){
        this.tramites[index_tramite].imagenes.splice(indice,1);
        this.tramites[index_tramite].datos_archivos.splice(indice,1)
        this.tramites[index_tramite].files.splice(indice,1)
        //this.files_format_blob.splice(indice,1)
    },
    save_image_edited(file,index_tramite){
      try {
        console.log(file);
        console.log(index_tramite);

        let file_formdata = new FormData();
        file_formdata.append("file", file.image);
        file_formdata.append("texto",  this.tramites[index_tramite].datos_archivos[file.index].token);
        file_formdata.append("nombre", this.tramites[index_tramite].datos_archivos[file.index].nombre);
        this.tramites[index_tramite].files[file.index] = file_formdata;
        var reader = new FileReader();
        reader.onload = async (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData

          var result = e.target.result;
          let imagenes = this.insert_element_at_postition(this.tramites[index_tramite].imagenes,file.index,result);
          imagenes.splice(file.index+1,1);
          console.log(imagenes);
          this.tramites[index_tramite].imagenes = [...imagenes];
        
        };

        reader.readAsDataURL(file.image);

        //this.imagenes[file.index] = file.image;
      } catch (error) {
        console.log(error);
      }
    },
    upload_images(index_initial){
      try {
        console.log('Por qué se llama por aca esto')
        console.log(index_initial);

        this.position_initial = index_initial;
        this.getImages();
      } catch (error) {
        console.log(error);
      }
    },
    set_index_file_to_move(index){
      console.log(index);
      this.index_to_move.old = index;

      this.dialog_move_file = true;
    },
    insert_element_at_postition(old_array,index,element){
      try {   
        console.log(old_array);
        old_array.splice(index, 0, element);
        return old_array;
      } catch (error) {
        console.log(error);
      }
    },
    async move_file(index_tramite){
      
      try {

          const index = this.index_to_move.new-1
          const new_array = {
            imagenes: await this.insert_element_at_postition(this.tramites[index_tramite].imagenes,index,this.tramites[index_tramite].imagenes[this.index_to_move.old]),
            datos_archivos: await this.insert_element_at_postition(this.tramites[index_tramite].datos_archivos,index,this.tramites[index_tramite].datos_archivos[this.index_to_move.old]),
            files: await this.insert_element_at_postition(this.tramites[index_tramite].files,index,this.tramites[index_tramite].files[this.index_to_move.old])

          }
        
          console.log(new_array);
          this.eliminar_archivo(this.index_to_move.old,this.step);
          this.dialog_move_file = false;
          this.$store.commit(
            "exitoso",
                "La imagen ha sivo movida de forma exitosa"
            );

          
      
      } catch (error) {
        console.log(error);
        this.$store.commit('error',"Algo ha salido mal moviendo la imagen "+error.message);
      }
    },
   
    
    async consultar_tipos_documentales() {

      let response = await this.$store.dispatch('llamado_get',{
        url:"https://siett.educandote.co/parse/classes/prueba01Sector/"+this.$store.state.dataUser.dataUser.sector,
        tipo_header: "parse"
      });


     const length =  response.data.tipos_documentales.length;

      for (let index = 0; index < length; index++) {
            this.tipos_documentales.push(response.data.tipos_documentales[index].nombre)
        }
        this.tipos_documentales_completo = response.data.tipos_documentales;

        console.log(this.tipos_documentales);
        this.nombre_endpoint_almacenamiento = `V1_${this.tipos_documentales[length-1]}`;

        const busqueda = await this.$store.dispatch('llamado_get',{
        url:`https://siett.educandote.co/types/collections/${this.nombre_endpoint_almacenamiento}`,
          //body: obj,
          tipo_header: "indexador"
        });

        console.log(busqueda);

     
        await this.$store.dispatch('SIETT/GET_TRAMITES');

    },
    async consultar_tipos_de_vehiculos(){
      try {
        await this.$store.dispatch('SIETT/GET_TIPOS_DE_VEHICULOS');
        
      } catch (error) {
        console.log(error);
      }
    },
    
    
    async pruebaDrop(event) {
      console.log("Funcion prueba Drop");
      console.log(event);
      console.log(event.archivo);
      this.scannerInicio = true;

      for (let index = 0; index < event.archivo.length; index++) {
        await this.addFiles_2(
          event.archivo[index],
          this.$store.state.token,
          event.obj[index].nombre,
          event.obj[index].tipo,
          this.step,
        );
      }
      this.terminarProcesoArchivador(this.step);
      //Comentarios
      // this.archivo2 = event.archivo
      // this.objUpload2 = event.obj
    },
    seleccionarFoto(event,index_tramite) {
      console.log(event);
      //event.setAttribute("style", "transform: rotate(" + 90 + "deg)");
      var reader = new FileReader();

      reader.onload = async (e) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData

        var result = e.target.result;

        if(this.position_initial == -1){
           this.tramites[index_tramite].imagenes.push(result);
        }else{
         const images = await this.insert_element_at_postition(this.tramites[index_tramite].imagenes,this.position_initial,result)
         this.tramites[index_tramite].imagenes = [...images];
        }
       
      };

      reader.readAsDataURL(event);
    },
    
    
  

    async concatenarDatos() {
      let obj = "";
      for (
        let index = 0;
        index <
        this.$store.state.formularios.inputs_tipo_documental.campos.length;
        index++
      ) {
        
        

        if(this.$store.state.formularios.inputs_tipo_documental.campos[index].key.toLowerCase()=='fecha'){
            obj = obj + `"fecha_documento_int":"${Date.parse(new Date(this.$store.state.formularios.inputs_tipo_documental.campos[index].value))}",`;
           
        }
        
        obj = obj + `"${this.$store.state.formularios.inputs_tipo_documental.campos[index].key}":"${
                        this.$store.state.formularios.inputs_tipo_documental.campos[index].value
                       }",`;

        
      }
      let objeto = obj.substring(0, obj.length - 1)
      //obj = obj +`"fecha_limite":"${this.$store.state.formularios.inputs_tipo_documental.fecha_limite}"`;
      console.log(objeto);
      obj = "{" + objeto + "}";
      console.log(this.$store.state.formularios.inputs_tipo_documental)

      return obj;
    },

    async addFiles_2(blob, token, index, tipo,index_tramite){
      //Se agrego un cuarto parametro llamado tipo el cual contendra
      //El tipo de archivo que se esta agregando .zip png jpg blob etc etc etc.
      //Con el fin de agregarlo al endpoint de almacenamiento y saber como debe hacerse la estructura de las url
      //Que permiten descargar los archivos la cual seria: http://url-endpoint/token/nombre.tipo
      let file = new FormData();
      console.log(token);
      file.append("file", blob);
      file.append("texto", token);
      file.append("nombre", index);

      console.log(blob);
      
      let obj = {
        nombre: index,
        token: token,
        tipo: tipo,
        autor: this.$store.state.dataUser.dataUser.nombre,
        sector: this.$store.state.dataUser.dataUser.sector,
        nombre_sector: this.$store.state.dataUser.dataUser.nombre_sector,
        //fecha_adjuntado: Date.parse(new Date()), // Indicara la fecha cuando se adjunto la imagen
        //meta_datos: [],
        estado_imagen: 'habilitada',
        imagenes_por_archivo: "1"
      };

      obj = JSON.stringify(obj);
      
    
      if(this.position_to_insert == -1){

          this.tramites[index_tramite].datos_archivos.push(obj);
          this.tramites[index_tramite].files.push(file);
          //this.files_format_blob.push(blob); 
      }else{
        
        const datos_archivos = await this.insert_element_at_postition(this.tramites[index_tramite].datos_archivos,this.position_to_insert,obj);
        const files = await this.insert_element_at_postition(this.tramites[index_tramite].files,this.position_to_insert,file);
        //const files_format_blob = await this.insert_element_at_postition(this.files_format_blob,this.position_to_insert,obj);
        this.tramites[index_tramite].datos_archivos = [...datos_archivos];
        this.tramites[index_tramite].files = [...files];
        //this.files_format_blob = [...files_format_blob];

        //console.log(datos_archivos);
        console.log(files);

      
      }
      
  
      this.indice++;
    },
    
    async addFiles(blob, token, index, tipo,index_tramite) {
      //Se agrego un cuarto parametro llamado tipo el cual contendra
      //El tipo de archivo que se esta agregando .zip png jpg blob etc etc etc.
      //Con el fin de agregarlo al endpoint de almacenamiento y saber como debe hacerse la estructura de las url
      //Que permiten descargar los archivos la cual seria: http://url-endpoint/token/nombre.tipo
      let file = new FormData();
      console.log(token);
      file.append("file", blob);
      file.append("texto", token);
      file.append("nombre", index);

      console.log(blob);
      /*
      let obj = {
        nombre: index,
        token: token,
        tipo: tipo,
        autor: this.$store.state.dataUser.dataUser.nombre,
        sector: this.$store.state.dataUser.dataUser.sector,
        nombre_sector: this.$store.state.dataUser.dataUser.nombre_sector,
        //fecha_adjuntado: Date.parse(new Date()), // Indicara la fecha cuando se adjunto la imagen
        //meta_datos: [],
        estado_imagen: 'habilitada'
      };
      obj = JSON.stringify(obj);
      */
      
      
      if(this.position_to_insert == -1){

          //this.tramites[index_tramite].datos_archivos.push(obj);
          this.tramites[index_tramite].files.push(file);
          //this.files_format_blob.push(blob); 
      }else{
        
        //const datos_archivos = await this.insert_element_at_postition(this.tramites[index_tramite].datos_archivos,this.position_to_insert,obj);
        const files = await this.insert_element_at_postition(this.tramites[index_tramite].files,this.position_to_insert,file);
        //const files_format_blob = await this.insert_element_at_postition(this.files_format_blob,this.position_to_insert,obj);
        //this.tramites[index_tramite].datos_archivos = [...datos_archivos];
        this.tramites[index_tramite].files = [...files];
        //this.files_format_blob = [...files_format_blob];

        //console.log(datos_archivos);
        console.log(files);

      
      }
      
  
      this.indice++;
    },
    async uploadImg(index_tramite) {
      //var token = 'TokenPrueba2'
      this.$store.state.load = true;

      //Activar un spinner load
      this.$store.state.SIETT.documentos_a_cargar = this.tramites[index_tramite].files.length;

      for (let index = 0; index < this.tramites[index_tramite].files.length; index++) {
        try {
          this.contadorImagensSubidas++;
          this.$store.state.SIETT.contador_carga_documentos++;
          this.$store.commit("auth");

          if (index + 1 == 1) {
            //Upload docu crea la carpeta y anexa la primera imagen
            var responseUploadImg = await axios.post(
              "https://drive1.educandote.co/siett/uploadDocu.php",
              this.tramites[index_tramite].files[index],
              {
                header: {
                  "Content-Type": "multipart/form-data",
                   Authorization: this.$store.state.header,
                },
              }
            );
            console.log("Upload Imagen 1", responseUploadImg);
          } else {
            //upgradeFolder.php anexa, datos al folder asociado al token

            var response = await axios.post(
              "https://drive1.educandote.co/siett/upgradeFolder.php",
              this.tramites[index_tramite].files[index],
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );

            console.log("Upload Imagen " + index);
            console.log(response);
          }
        } catch (error) {
          //Posteriormente indicar cual sera el error en un dialog
          console.log(error);
          this.$store.state.load = false;
          return false; //Se retorna falso cuando existe un error en la subida de alguna imagen
        }
      }

      return true;
    },
    async uploadImgPDF(index_tramite){
      this.$store.state.load = true;

      //Activar un spinner load
      this.tramites[index_tramite].datos_archivos = [];
      for (let index = 0; index < this.tramites[index_tramite].files.length; index++) {
        try {
          this.contadorImagensSubidas++;
          this.$store.state.SIETT.contador_carga_documentos++;
          this.$store.commit("auth");

          var responseUploadImg = await axios.post(
              "https://drive1.educandote.co/siett/uploadPDF.php",
              this.tramites[index_tramite].files[index],
              {
                header: {
                  "Content-Type": "multipart/form-data",
                   Authorization: this.$store.state.header,
                },
              }
            );

            if(responseUploadImg!=null){
              console.log('Entrando')
                this.tramites[index_tramite].datos_archivos = [...responseUploadImg.data];

                  for (let index_2 = 0 ; index_2 < responseUploadImg.data.length; index_2++) {
                     
 
                      let format_file = responseUploadImg.data[index_2].split('.');

                      let aux = format_file[0].split('-');

                      let index_to_insert = parseInt(aux[1]);

                      let obj = {
                        nombre: format_file[0],
                        token: this.$store.state.token,
                        tipo: format_file[1],
                        autor: this.$store.state.dataUser.dataUser.nombre,
                        sector: this.$store.state.dataUser.dataUser.sector,
                        nombre_sector: this.$store.state.dataUser.dataUser.nombre_sector,
                        //fecha_adjuntado: Date.parse(new Date()), // Indicara la fecha cuando se adjunto la imagen
                        //meta_datos: [],
                        estado_imagen: 'habilitada',
                        imagenes_por_archivo: "1",
                      };




                      this.tramites[index_tramite].datos_archivos[index_to_insert] = JSON.stringify(obj);

                      console.log(this.tramites[index_tramite].datos_archivos[index_to_insert]);

                      //this.tramites[index_tramite].datos_archivos[index] = JSON.stringify(this.tramites[index_tramite].datos_archivos[index]);
                  }

            }else{
              return false;
            }

            

            console.log(index_tramite);
            console.log(this.tramites);

           
            console.log(responseUploadImg);
        } catch (error) {
          //Posteriormente indicar cual sera el error en un dialog
          console.log(error);
          this.$store.state.load = false;
          return false; //Se retorna falso cuando existe un error en la subida de alguna imagen
        }
      }

      return true;
    },
    async getImages() {
      this.ws.send("1100");
      this.scannerInicio = true;
    },

   
    
    async terminarProcesoArchivador(index_tramite) {
      
      this.contadorImagensSubidas = 0;
      this.$store.state.SIETT.contador_carga_documentos = 0;
      this.subiendoImagenes = true;
      if (this.tramites[index_tramite].imagenes.length > 0 || this.tramites[index_tramite].datos_archivos.length > 0 || this.tramites[index_tramite].files.length > 0 ) {
        this.$store.state.dialogCargando = true
        //var subidaImagenes = await this.uploadImg(index_tramite); //Este upload permite subir todo ahora
        //var subidaImagenes = await this.uploadImgPDF(index_tramite); //Este upload permite subir todo ahora
        var subidaImagenes = await this.uploadImg(index_tramite);

        if (subidaImagenes) {
          console.log("Terminando desde archivador");
          try {
            //Este objeto va a cambiar segun sea el documento seleccionado se va a mandar a un endpoint
            //Especifico con la estructura
            //El body del endpoint tiene que ser dinamico y tener como keys los label de los tipo documental y como valor el model del input


            //Dato: Si el crud_documentos: doy la opcion de seleccionar como se llenará el archivo
            //debo poner el select y el text-field, y si es select debo dar una ventana para que llene 
            //todas las posibilidades para de esta forma tener más flexibilidad

            let Tipo_de_tramite = {
              ids: "",
              names: ""
            };

            for (let index = 0; index < this.tramites[index_tramite].Tipo_de_tramite.length; index++) {
              Tipo_de_tramite.names = `${Tipo_de_tramite.names} - ${this.tramites[index_tramite].Tipo_de_tramite[index].name}`;
              Tipo_de_tramite.ids = `${Tipo_de_tramite.ids} - ${this.tramites[index_tramite].Tipo_de_tramite[index].id}`;
              
            }

            let obj = {
              Placa: this.$store.state.SIETT.form.placa,
              Fecha: this.tramites[index_tramite].Fecha ,//this.$store.state.SIETT.form.fecha,
              fecha_documento_int: 0,
              Tipo_de_tramite: Tipo_de_tramite.names, //this.$store.state.SIETT.form.tramite_seleccionado.name,
              id_Tipo_de_tramite: Tipo_de_tramite.ids,//this.$store.state.SIETT.form.tramite_seleccionado.id,
              Documento_del_propietario: this.tramites[index_tramite].Documento_del_propietario,//this.$store.state.SIETT.form.documento_propietario,
              estatus: "HABILITADA",  
              relacionado_con: "",
              Relacionado_con: "",
              Observaciones: this.tramites[index_tramite].Observaciones,
              Parque_automotor: this.tramites[index_tramite].Parque_automotor,
              autor: this.$store.state.dataUser.dataUser.nombre,
            }

            try {

              if(this.tramites[index_tramite].Fecha==""){
                obj.fecha_documento_int = 0;
              }else{
                obj.fecha_documento_int = Date.parse(new Date(this.tramites[index_tramite].Fecha)); //Date.parse( new Date(this.$store.state.SIETT.form.fecha));
              }

              
            } catch (error) {
              console.log(error);
            }

            obj.fecha_creacion_int = Date.parse( new Date ());
            obj.extended_properties = JSON.stringify({});
            obj.order = Date.parse(new Date());

            let rango = {
                rango: obj.Placa.slice(0,3),
                numero: obj.Placa.slice(3)
            };

            

            obj.id = Date.parse(new Date()).toString();            
            obj.Rango_placa = rango.rango;
            obj.Numero_rango_placa = rango.numero;
            obj.Tipo_de_vehiculo = this.tipo_de_vehiculo;
            

            const busqueda = await this.$store.dispatch('llamado_post',{
              url:`https://siett.educandote.co/types/collections/${this.nombre_endpoint_almacenamiento}/documents`,
              body: obj,
              tipo_header: "indexador"
            });
            console.log(busqueda);

            const collections = 'V1_SIETT_DOCUMENT_V3_2'

            const carpeta = await this.$store.dispatch('llamado_post',{
              url:`https://siett.educandote.co/types/collections/CARPETAS_${collections}/documents`,
              body: {
                "nombre_carpeta": "Carpeta 1",
                "id_carpeta": "1",
                "pertenece_a": busqueda.data.id.toString(),
                "estado_carpeta": "ACTIVO",
                "fecha_creacion_int": Date.parse(new Date()),
                "order": Date.parse(new Date()),
                "extended_properties": JSON.stringify({}),
              },
              tipo_header: "indexador"
            });

            console.log(carpeta);
              let body = "";
              console.log('Prueba modificada');
              console.log(this.tramites);
              try {
                for (let index = 0; index < this.tramites[index_tramite].datos_archivos.length; index++) {
                  let info_file = JSON.parse(this.tramites[index_tramite].datos_archivos[index]);
                  console.log(info_file);
                  body = body + `{"id_busqueda": "${busqueda.data.id.toString()}", "id_carpeta": "${carpeta.data.id.toString()}", "autor": "${info_file.autor}", "estado_imagen": "${info_file.estado_imagen}", "meta_datos": "", "nombre": "${info_file.nombre}", "nombre_sector": "${info_file.nombre_sector}", "sector": "${info_file.sector}", "tipo": "${info_file.tipo}", "token": "${info_file.token}", "fecha_creacion_int": ${Date.parse(new Date())}, "order": ${Date.parse(new Date())+(index*250)}, "extended_properties": "{}","imagenes_por_archivo": "${info_file.imagenes_por_archivo}"}\n`
                }
              } catch (error) {
                console.log(error);
              }
              
              
             
            const imagenes = await this.$store.dispatch('llamado_post',{
              url:`https://siett.educandote.co/types/collections/IMAGENES_${collections}/documents/import?action=create`,
              body: body,
              tipo_header: "indexador"
            });


            console.log(imagenes);

            this.$store.commit('SET_CANTIDAD_DOCUMENTOS_SUBIDOS',this.tramites[index_tramite].imagenes.length)
            

            //this.tramites[index_tramite].imagenes = [];
            //this.tramites[index_tramite].files = [];
            this.indice = 0;
            
            //this.tramites[index_tramite].datos_archivos = []; //Variable agregada por fry
            this.contadorImagensSubidas = 0;
            this.$store.state.SIETT.contador_carga_documentos = 0;
            this.reset(index_tramite);

            this.tramites[index_tramite].status = " -Completado- ";
            this.$store.commit("crearToken", 25);
            this.scannerInicio = false;
            this.$store.commit(
              "exitoso",
              "Los archivos han sido escaneados exitosamente"
            );
             this.$store.state.load = false;
          } catch (error) {
            this.$store.commit(
              "error",
              "Ha ocurrido un error inesperado en el momento de almacenar los datos en la coleccion "+error.message
            );
            console.log(error);
          }
        }
      } else {
        this.$store.commit(
          "error",
          "Por favor escanea algunas imagenes antes de enviar los datos"
        );
      }
      this.$store.state.dialogCargando = false
    },

    async generar_qr(){
      
      console.log('xssds')
      var doc = new jsPDF('l', 'mm',[130.23,410]);
      doc.addImage(require('../../assets/alcaldia.png'),"PNG" ,1, 20, 140, 100) 
      doc.setFont("times", "normal");
      doc.setFontSize(60)
      //doc.text('SIETT', 155, 45);
      
      //doc.setFontSize(10)
      doc.text(`${this.$store.state.SIETT.form.placa}`, 155, 110);
      //doc.text(this.token_caso, 173, 70);

      //doc.text('Remitente:', 115, 85);


      
      /*
      if(nombre.length==1){
          altura = altura + 8
          doc.text(nombre[0], 115, altura);
      }else{
          for (let index = 0; index < nombre.length; index++) {
              if(index%2!=0){
                  altura = altura + 8
                  doc.text(nombre[index], 115, altura);
              }
          }
      }
      */
      
      let url = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://siett.educandote.co/searchs/buscar_documentos/"+this.$store.state.SIETT.form.placa;
      doc.addImage(url,"PNG" , 270, 15, 100, 100) 
      doc.save(this.$store.state.SIETT.form.placa+'.pdf');
      
  },
    seleccionTipoDocumental() {
      //'Orden de pago','Impuesto predial','Impuesto ICA','Resoluciones','Historias laborales'
      this.$store.state.formularios.inputs_tipo_documental = {
        campos: [],
      };
      console.log(this.selectDocumento);

      console.log(this.tipos_documentales_completo);
      let aux = this.tipos_documentales_completo.filter(
        (item) =>
          item.nombre
            .toLowerCase()
            .indexOf(this.selectDocumento.toLowerCase()) !== -1
      );
    
      console.log(aux);
      this.nombre_endpoint_almacenamiento = aux[0].nombre_endpoint_almacenamiento;

      for (let index = 0; index < aux[0].parametros.length; index++) {
        aux[0].parametros[index].value = ""
        
      }

      console.log(this.$store.state.formularios.inputs_tipo_documental.campos);
      this.$store.state.formularios.inputs_tipo_documental.campos = [...aux[0].parametros]
    },
    reset(index_tramite) {
      this.subiendoImagenes = false;
      this.select = [];
      this.now = null;
      this.selectDocumento = "";
      this.nombreDocumento = "";
      this.tramites[index_tramite].imagenes = [];
      this.tramites[index_tramite].files = []; 
      this.tramites[index_tramite].datos_archivos = [];
      this.tramites[index_tramite].status = "";
      
      this.$store.state.SIETT.form.tramite_seleccionado = {};
      //this.$store.state.SIETT.form.placa = "";
      this.$store.state.SIETT.form.fecha = "";
      this.$store.state.SIETT.form.documento_propietario = "";

      this.$store.state.formularios.inputs_tipo_documental = {
        campos: [],
      };
    },
  },
  computed: {
    ...mapState("dataUser", ["dataUser"]),
    ...mapState("SIETT",["form"]),
  },
}
</script>