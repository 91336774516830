<template>
    <v-sheet class="mx-5" width="100%">
        <v-window v-model="image_select">
            <Paginacion @buscar="image_select=$event-1" :cantidad_paginas="imagenes.length" />
            <v-window-item v-for="(imagen,index) in imagenes" :key="index">

                    <CropperImage 
                        @delete_image="$emit('eliminar',index)" 
                        @save_image_edited="$emit('save_image_edited',{image:$event,index:index})"
                        @move_image="$emit('move_image',index)"
                        @upload_images="$emit('upload_images',index)" 
                        :image="imagen" 
                />
            </v-window-item>
        </v-window>
    </v-sheet>
</template>
<script>
//import ToolbarMultiIcons from '../atomos/ToolbarMultiIcons.vue'
import CropperImage from './CropperImage.vue'
export default {
    components: {
        //ToolbarMultiIcons
        CropperImage,
    },
    props: {
        imagenes: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            image_select: 0, 
        }
    },
    created(){
        
        //this.imagenes.push(require('../../assets/archivador.png'));
        //this.imagenes.push(require('../../assets/bandeja_entrada.png'));
        //this.imagenes.push(require('../../assets/buscar.png'));
        //this.imagenes.push(require('../../assets/empalme.png'));

        //Bandeja,archivador,buscar,empalme

        //archivador,buscar,bandeja,empalme
        
    },
    methods: {
       
        
    }
    
}
</script>